body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100vh;
}

.geosuggest {
  /* font-size: 18px; */
  /* font-size: 1rem; */
  position: relative;
  /* width: 50%; */
  /* margin: 1em auto; */
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  /* border: 2px solid transparent; */
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 0 1px #3d464d;
  /* padding: .5em 1em; */
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.ant-menu-inline {
  border: none !important;
}

@media (max-width: 580px) {
  .qr-main {
    height: 100%;
    width: 100%;
    text-align: 'center';
    display: inline;
  }
  
  .qr-scanner {
    height: 240px;
    width: 320px;
    margin: 0 auto;
  }

  .qr-div-left {
    width: 100%;
  }

  .qr-div-right {
    width: 100%;
    margin-top: 150px;
  }

  .qr-display-txt {
    text-align: center;
  }
}

@media (min-width: 581px) {
  .qr-main {
    height: 100%;
    width: 100%;
    text-align: 'center';
    display: inline-flex;
  }
  
  .qr-scanner {
    height: 240px;
    width: 320px;
  }

  .qr-div-left {
    width: 50%;
  }

  .qr-div-right {
    width: 50%;
    margin-top: 120px;
  }

  .qr-display-txt {
    text-align: center;
  }
}

@media (min-width: 780px) {
  .qr-main {
    height: 100%;
    width: 100%;
    text-align: 'center';
    display: inline-flex;
  }
  
  .qr-scanner {
    height: 240px;
    width: 320px;
  }

  .qr-div-left {
    width: 50%;
  }

  .qr-div-right {
    width: 50%;
    margin-top: 120px;
  }

  .qr-display-txt {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .qr-main {
    height: 100%;
    width: 100%;
    text-align: 'center';
    display: inline-flex;
  }

  .qr-scanner {
    height: 240px;
    width: 320px;
  }

  .qr-div-left {
    width: 35%;
  }

  .qr-div-right {
    width: 65%;
    margin-top: 120px;
  }

  .qr-display-txt {
    text-align: left;
  }
}

.payment-detail-sw {
  background-color: rgb(239, 239, 239) !important;
  max-width: 131px !important;
  width: 50%;
}

.payment-detail-sw .ant-switch-inner {
  color: #54BFC5 !important;
  background-color: rgb(239, 239, 239) !important;
}

#payment-detail-switch button,
#payment-detail-switch button {
  color: #54BFC5 !important;
  background-color: #fafafa imp !important;
}